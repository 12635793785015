import React from 'react';

import Header from '@modules/header/header';
import Footer from '@modules/footer/footer';
import Gallery from '@modules/gallery/gallery';

const GalleryPage = () => {
    return (
        <>
            <Header />
            <Gallery />
            <Footer />
        </>
    );
};

export default GalleryPage;
