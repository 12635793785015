import React from 'react';

import Header from '@modules/header/header';
import Footer from '@modules/footer/footer';
import Menu from '@modules/menu/menu';

const MenuPage = () => {
    return (
        <>
            <Header />
            <Menu />
            <Footer />
        </>
    );
};

export default MenuPage;
