import React from 'react';

import Title from './components/title';
import Description from './components/description';
import ButtonDownload from './components/button-download';
import Image from './components/image';

import './style.css';

const Menu = () => {
    return (
        <main data-aos='fade-down'>
            <section className='container menu'>
                <Image />
                <div>
                    <Title />
                    <Description />
                    <ButtonDownload />
                </div>
            </section>
        </main>
    );
};

export default Menu;
