import React from 'react';
import { useTranslation } from 'react-i18next';

const Copyright = () => {
    const { t } = useTranslation();
    return (
        <div className='footer-copyright'>
            <span className='footer-description'>{t('copyright')}</span>
        </div>
    );
};

export default Copyright;
