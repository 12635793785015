import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Logo from './components/logo';
import Navbar from './components/navbar';
import ButtonLang from './components/button-lang';
import MobileMenu from './components/mobile-menu';
import menuIcon from './assets/menu-icon.png';

import './style.css';

const Header = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    const [openMenu, setOpenMenu] = useState(false);
    function handleChangePage(path) {
        navigate(path);
    }

    function isCurrentPath(path) {
        return location.pathname === path;
    }
    return (
        <header>
            <div className='container header-container'>
                <Logo handleChangePage={handleChangePage} />
                <div className='header-inner'>
                    <Navbar handleChangePage={handleChangePage} isCurrentPath={isCurrentPath} t={t} />
                    <ButtonLang />
                </div>
                <button onClick={() => setOpenMenu(true)} className='mobile-menu-menu-button'>
                    <img width={'25px'} src={menuIcon} alt='menu' />
                </button>
            </div>
            {openMenu ? (
                <MobileMenu
                    setOpenMenu={setOpenMenu}
                    handleChangePage={handleChangePage}
                    isCurrentPath={isCurrentPath}
                    t={t}
                />
            ) : null}
        </header>
    );
};

export default Header;
