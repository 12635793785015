import React from 'react';
import { useTranslation } from 'react-i18next';

const Title = () => {
    const { t } = useTranslation();

    return (
        <>
            <h2 className='menu-title'>{t('menuTitle')}</h2>
            <div className='menu-title-decoration-line-lg'></div>
            <div className='menu-title-decoration-line-md'></div>
        </>
    );
};

export default Title;
