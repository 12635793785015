import React from 'react';
import { useTranslation } from 'react-i18next';

const ButtonLang = () => {
    const { i18n } = useTranslation();
    const changeLanguage = lng => {
        i18n.changeLanguage(lng);
    };
    return (
        <>
            {i18n.language !== 'en' ? (
                <button onClick={() => changeLanguage('en')} className='header-button-lang'>
                    English
                </button>
            ) : null}
            {i18n.language !== 'ru' ? (
                <button onClick={() => changeLanguage('ru')} className='header-button-lang'>
                    Русский
                </button>
            ) : null}
        </>
    );
};

export default ButtonLang;
