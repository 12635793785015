import React from 'react';

const Description = ({ content, ...other }) => {
    return (
        <a className='contacts-description' {...other}>
            {content}
        </a>
    );
};

export default Description;
