import React from 'react';

const Pagination = ({ currentPage, handlePage }) => {
    return (
        <div className='gallery-pagination-container'>
            <div
                onClick={() => handlePage(1)}
                className={currentPage === 1 ? 'gallery-pagination-page-active' : 'gallery-pagination-page'}
            >
                1
            </div>
            <div
                onClick={() => handlePage(2)}
                className={currentPage === 2 ? 'gallery-pagination-page-active' : 'gallery-pagination-page'}
            >
                2
            </div>
            <div
                onClick={() => handlePage(3)}
                className={currentPage === 3 ? 'gallery-pagination-page-active' : 'gallery-pagination-page'}
            >
                3
            </div>
        </div>
    );
};

export default Pagination;
