import React from 'react';

import Header from '@modules/header/header';
import Footer from '@modules/footer/footer';
import Home from '@modules/home/home';

const HomePage = () => {
    return (
        <>
            <Header />
            <Home />
            <Footer />
        </>
    );
};

export default HomePage;
