import React from 'react';
import { useTranslation } from 'react-i18next';

import menuPDF from '../assets/menu.pdf';
import pdfIcon from '../assets/pdf-icon.png';

const ButtonDownload = () => {
    const { t } = useTranslation();

    return (
        <a href={menuPDF} target='blank' className='menu-download'>
            <img src={pdfIcon} className='menu-dowload-icon' alt='pdf' />
            <span>{t('menuDownload')}</span>
        </a>
    );
};

export default ButtonDownload;
