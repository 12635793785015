import React from 'react';
import { useTranslation } from 'react-i18next';

const Description = () => {
    const { t } = useTranslation();

    return (
        <div className='about-restaurant-description'>
            <p>{t('aboutRestaurantDescription1')}</p>
            <p>{t('aboutRestaurantDescription2')}</p>
            <p>{t('aboutRestaurantDescription3')}</p>
        </div>
    );
};

export default Description;
