import React from 'react';
import { useTranslation } from 'react-i18next';

import locationIcon from '../assets/location-icon.png';
import phoneIcon from '../assets/phone-icon.png';
import facebookIcon from '../assets/facebook-icon.png';
import instagramIcon from '../assets/instagram-icon.png';

const Contacts = () => {
    const { t } = useTranslation();

    return (
        <section className='footer-block'>
            <div className='footer-title'>
                <h6 className='footer-title-text'>{t('contacts')}</h6>
                <div className='footer-title-decoration-line-lg'></div>
                <div className='footer-title-decoration-line-md'></div>
            </div>
            <a
                href='https://2gis.kg/bishkek/firm/70000001035157143/center/74.5955%2C42.8759/zoom/17.2717'
                target='blank'
                className='footer-line'
            >
                <img src={locationIcon} className='footer-location-icon' alt='location' />
                <p className='footer-description'>{t('chuiAve136')}</p>
            </a>

            <a href='tel:+996700533633' target='blank' className='footer-line'>
                <img src={phoneIcon} className='footer-phone-icon' alt='phone' />
                <p className='footer-description'>+996 700 533 633</p>
            </a>
            <div className='footer-line footer-social-media'>
                <a href='https://www.facebook.com/cyclone.bishkek/' target='blank'>
                    <img src={facebookIcon} className='footer-facebook-icon' alt='facebook' />
                </a>
                <a href='https://www.instagram.com/cyclone.bishkek/' target='blank'>
                    <img src={instagramIcon} className='footer-instagram-icon' alt='instagram' />
                </a>
            </div>
        </section>
    );
};

export default Contacts;
