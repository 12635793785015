import React from 'react';

import Image from './image';

import gallery211 from '../assets/gallery-2-1-1.jpg';
import gallery212 from '../assets/gallery-2-1-2.jpg';
import gallery221 from '../assets/gallery-2-2-1.jpg';
import gallery222 from '../assets/gallery-2-2-2.jpg';
import gallery223 from '../assets/gallery-2-2-3.jpg';
import gallery224 from '../assets/gallery-2-2-4.jpg';
import gallery231 from '../assets/gallery-2-3-1.jpg';
import gallery232 from '../assets/gallery-2-3-2.jpg';
import gallery233 from '../assets/gallery-2-3-3.jpg';

const Page2 = ({ currentImage, handleCurrentImage, handleSwitchOpenImage }) => {
    return (
        <>
            <div className='gallery-column'>
                <Image
                    src={gallery211}
                    currentImage={currentImage}
                    handleCurrentImage={handleCurrentImage}
                    handleSwitchOpenImage={handleSwitchOpenImage}
                />
                <Image
                    src={gallery212}
                    currentImage={currentImage}
                    handleCurrentImage={handleCurrentImage}
                    handleSwitchOpenImage={handleSwitchOpenImage}
                />
            </div>
            <div className='gallery-column'>
                <Image
                    src={gallery221}
                    currentImage={currentImage}
                    handleCurrentImage={handleCurrentImage}
                    handleSwitchOpenImage={handleSwitchOpenImage}
                />
                <Image
                    src={gallery222}
                    currentImage={currentImage}
                    handleCurrentImage={handleCurrentImage}
                    handleSwitchOpenImage={handleSwitchOpenImage}
                />
                <Image
                    src={gallery223}
                    currentImage={currentImage}
                    handleCurrentImage={handleCurrentImage}
                    handleSwitchOpenImage={handleSwitchOpenImage}
                />
                <Image
                    src={gallery224}
                    currentImage={currentImage}
                    handleCurrentImage={handleCurrentImage}
                    handleSwitchOpenImage={handleSwitchOpenImage}
                />
            </div>
            <div className='gallery-column'>
                <Image
                    src={gallery231}
                    currentImage={currentImage}
                    handleCurrentImage={handleCurrentImage}
                    handleSwitchOpenImage={handleSwitchOpenImage}
                />
                <Image
                    src={gallery232}
                    currentImage={currentImage}
                    handleCurrentImage={handleCurrentImage}
                    handleSwitchOpenImage={handleSwitchOpenImage}
                />
                <Image
                    src={gallery233}
                    currentImage={currentImage}
                    handleCurrentImage={handleCurrentImage}
                    handleSwitchOpenImage={handleSwitchOpenImage}
                />
            </div>
        </>
    );
};

export default Page2;
