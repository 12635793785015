import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './style.css';

const NotFound = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    function handleChangePage(path) {
        navigate(path);
    }
    return (
        <div className='container not-found'>
            <h2>{t('error404')}</h2>
            <p onClick={() => handleChangePage('/')} className={`not-found-redirect`}>
                {t('home')}
            </p>
        </div>
    );
};

export default NotFound;
