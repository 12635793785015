import React from 'react';
import { pages } from '../consts';

const Navbar = ({ handleChangePage, isCurrentPath, t }) => {
    return (
        <nav>
            {pages.map(page => (
                <span
                    onClick={() => handleChangePage(page.path)}
                    className={`header-item ${isCurrentPath(page.path) ? 'nav-item-active' : 'nav-item'}`}
                    key={page.id}
                >
                    {t(page.translationKey)}
                </span>
            ))}
        </nav>
    );
};

export default Navbar;
