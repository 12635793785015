import React from 'react';

import Image from './image';

import gallery311 from '../assets/gallery-3-1-1.jpg';
import gallery312 from '../assets/gallery-3-1-2.jpg';
import gallery313 from '../assets/gallery-3-1-3.jpg';
import gallery314 from '../assets/gallery-3-1-3.jpg';
import gallery321 from '../assets/gallery-3-2-1.jpg';
import gallery322 from '../assets/gallery-3-2-2.jpg';
import gallery323 from '../assets/gallery-3-2-3.jpg';
import gallery324 from '../assets/gallery-3-2-4.jpg';
import gallery331 from '../assets/gallery-3-3-1.jpg';
import gallery332 from '../assets/gallery-3-3-2.jpg';
import gallery333 from '../assets/gallery-3-3-3.jpg';
import gallery334 from '../assets/gallery-3-3-4.jpg';

const Page3 = ({ currentImage, handleCurrentImage, handleSwitchOpenImage }) => {
    return (
        <>
            <div className='gallery-column'>
                <Image
                    src={gallery311}
                    currentImage={currentImage}
                    handleCurrentImage={handleCurrentImage}
                    handleSwitchOpenImage={handleSwitchOpenImage}
                />
                <Image
                    src={gallery312}
                    currentImage={currentImage}
                    handleCurrentImage={handleCurrentImage}
                    handleSwitchOpenImage={handleSwitchOpenImage}
                />
                <Image
                    src={gallery313}
                    currentImage={currentImage}
                    handleCurrentImage={handleCurrentImage}
                    handleSwitchOpenImage={handleSwitchOpenImage}
                />
                <Image
                    src={gallery314}
                    currentImage={currentImage}
                    handleCurrentImage={handleCurrentImage}
                    handleSwitchOpenImage={handleSwitchOpenImage}
                />
            </div>
            <div className='gallery-column'>
                <Image
                    src={gallery321}
                    currentImage={currentImage}
                    handleCurrentImage={handleCurrentImage}
                    handleSwitchOpenImage={handleSwitchOpenImage}
                />
                <Image
                    src={gallery322}
                    currentImage={currentImage}
                    handleCurrentImage={handleCurrentImage}
                    handleSwitchOpenImage={handleSwitchOpenImage}
                />
                <Image
                    src={gallery323}
                    currentImage={currentImage}
                    handleCurrentImage={handleCurrentImage}
                    handleSwitchOpenImage={handleSwitchOpenImage}
                />
                <Image
                    src={gallery324}
                    currentImage={currentImage}
                    handleCurrentImage={handleCurrentImage}
                    handleSwitchOpenImage={handleSwitchOpenImage}
                />
            </div>
            <div className='gallery-column'>
                <Image
                    src={gallery331}
                    currentImage={currentImage}
                    handleCurrentImage={handleCurrentImage}
                    handleSwitchOpenImage={handleSwitchOpenImage}
                />
                <Image
                    src={gallery332}
                    currentImage={currentImage}
                    handleCurrentImage={handleCurrentImage}
                    handleSwitchOpenImage={handleSwitchOpenImage}
                />
                <Image
                    src={gallery333}
                    currentImage={currentImage}
                    handleCurrentImage={handleCurrentImage}
                    handleSwitchOpenImage={handleSwitchOpenImage}
                />
                <Image
                    src={gallery334}
                    currentImage={currentImage}
                    handleCurrentImage={handleCurrentImage}
                    handleSwitchOpenImage={handleSwitchOpenImage}
                />
            </div>
        </>
    );
};

export default Page3;
