import React from 'react';

import closeIcon from '../assets/close-icon.png';

const FullScreenImage = ({ handleSwitchOpenImage, fullScreenImage }) => {
    return (
        <div
            className='gallery-fullscreen-image'
            onClick={() => {
                handleSwitchOpenImage();
            }}
        >
            <div className='gallery-fullscreen-actions'>
                <button className='gallery-close-button'>
                    <img src={closeIcon} width='25px' alt='close' />
                </button>
            </div>
            <img
                onClick={e => {
                    e.stopPropagation();
                }}
                src={fullScreenImage}
                alt='gallery'
            />
            ;
        </div>
    );
};

export default FullScreenImage;
