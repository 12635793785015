import React from 'react';
import { useTranslation } from 'react-i18next';

const ButtonReserve = () => {
    const { t } = useTranslation();

    return (
        <a href='tel:+996700533633' target='blank' className='about-restaurant-reserve'>
            {t('aboutRestaurantReserve')}
        </a>
    );
};

export default ButtonReserve;
