import React from 'react';

import Logo from './components/logo';
import Contacts from './components/contacts';
import AboutRestaurant from './components/about-restaurant';
import Copyright from './components/copyright';

import './style.css';

const Footer = () => {
    return (
        <footer>
            <div className='container footer'>
                <Logo />
                <AboutRestaurant />
                <Contacts />
            </div>
            <Copyright />
        </footer>
    );
};

export default Footer;
