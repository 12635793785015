import React from 'react';
import Aos from 'aos';
import { Helmet } from 'react-helmet-async';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { pages } from './consts';

import 'aos/dist/aos.css';
import './App.css';

function App() {
    Aos.init({
        duration: 700,
    });

    return (
        <BrowserRouter>
            <Routes>
                {pages.map(route => (
                    <Route
                        exact
                        key={route.id}
                        path={route.path}
                        element={
                            <>
                                <Helmet>
                                    <title>{route.title}</title>
                                </Helmet>
                                {route.element}
                            </>
                        }
                    />
                ))}
            </Routes>
        </BrowserRouter>
    );
}

export default App;
