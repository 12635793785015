import React from 'react';

import closeIcon from '../assets/close-icon.png';
import ButtonLang from './button-lang';

import { pages } from '../consts';

const MobileMenu = ({ handleChangePage, isCurrentPath, t, setOpenMenu }) => {
    return (
        <>
            <div className='mobile-menu-container'>
                <div className='mobile-menu-actions'>
                    <button onClick={() => setOpenMenu(false)} className='mobile-menu-close-button'>
                        <img src={closeIcon} width='25px' alt='close' />
                    </button>
                </div>

                <div className='mobile-menu'>
                    {pages.map(page => (
                        <span
                            onClick={() => handleChangePage(page.path)}
                            className={`header-item ${isCurrentPath(page.path) ? 'nav-item-active' : 'nav-item'}`}
                            key={page.id}
                        >
                            {t(page.translationKey)}
                        </span>
                    ))}
                    <ButtonLang />
                </div>
            </div>
        </>
    );
};

export default MobileMenu;
