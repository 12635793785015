import React from 'react';
import { useTranslation } from 'react-i18next';

const CarouselItem = ({ isCurrent, item, reviews, handleChangeCurrent }) => {
    const { t } = useTranslation();

    return (
        <div
            style={{
                backgroundImage: `url(${item.image})`,
                display: isCurrent(item.id) || isCurrent(item.id + 1) ? 'block' : 'none',
                position: isCurrent(item.id) || isCurrent(item.id + 1) ? 'absolute' : 'initial',
            }}
            className='home-carousel-image-container fade-in-animation'
        >
            <div className='home-carousel-content'>
                <p data-aos={isCurrent(item.id) ? 'fade-left' : ''} className='home-carousel-review'>
                    {t(item.reviewKey)}
                </p>
                <p
                    data-aos={isCurrent(item.id) ? 'fade-up' : ''}
                    data-aos-delay='500'
                    className='home-carousel-review-author'
                >
                    {t(item.reviewAuthorKey)}
                </p>
                <div className='home-carousel-button-container'>
                    {reviews.map(item => (
                        <div
                            key={item.id}
                            onClick={() => handleChangeCurrent(item.id)}
                            className={isCurrent(item.id) ? 'home-carousel-button-active' : 'home-carousel-button'}
                        >
                            <div className='home-carousel-button-inner'></div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CarouselItem;
