import React, { useState } from 'react';

import welcomeCarouselImage1 from '../assets/welcome-carousel-1.jpg';
import welcomeCarouselImage2 from '../assets/welcome-carousel-2.jpg';
import welcomeCarouselImage3 from '../assets/welcome-carousel-3.jpg';

const media = [welcomeCarouselImage1, welcomeCarouselImage2, welcomeCarouselImage3];

const WelcomeCarousel = () => {
    const [current, setCurrent] = useState(welcomeCarouselImage1);
    function handleChangeCurrent(src) {
        setCurrent(src);
    }
    function isCurrent(src) {
        return current === src;
    }
    return (
        <div className='home-welcome-carousel'>
            <img src={current} width={'100%'} alt='welcome' />
            <div className='home-welcome-carousel-button-container'>
                {media.map((item, index) => (
                    <div
                        key={index}
                        onClick={() => handleChangeCurrent(item)}
                        className={
                            isCurrent(item) ? 'home-welcome-carousel-button-active' : 'home-welcome-carousel-button'
                        }
                    >
                        <div className='home-welcome-carousel-button-inner'></div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default WelcomeCarousel;
