import React, { useEffect, useState } from 'react';

import CarouselItem from './carousel-item';

import image1 from '../assets/carousel-1.png';
import image2 from '../assets/carousel-2.png';
import image3 from '../assets/carousel-3.jpg';
import image4 from '../assets/carousel-4.jpg';
import image5 from '../assets/carousel-5.jpg';
import image6 from '../assets/carousel-6.jpg';

const reviews = [
    {
        reviewKey: 'review1',
        reviewAuthorKey: 'review1Author',
        image: image1,
        id: 1,
    },
    {
        reviewKey: 'review2',
        reviewAuthorKey: 'review2Author',
        image: image2,
        id: 2,
    },
    {
        reviewKey: 'review3',
        reviewAuthorKey: 'review3Author',
        author: 'Артем, гость',
        image: image3,
        id: 3,
    },
    {
        reviewKey: 'review4',
        reviewAuthorKey: 'review4Author',
        image: image4,
        id: 4,
    },
    {
        reviewKey: 'review5',
        reviewAuthorKey: 'review5Author',
        image: image5,
        id: 5,
    },
    {
        reviewKey: 'review6',
        reviewAuthorKey: 'review6Author',
        image: image6,
        id: 6,
    },
];

const Carousel = () => {
    const [current, setCurrent] = useState(1);
    function handleChangeCurrent(id) {
        setCurrent(id);
    }
    function isCurrent(id) {
        return current === id;
    }
    useEffect(() => {
        setInterval(() => {
            setCurrent(prev => (prev < 6 ? prev + 1 : 1));
        }, 4000);
    }, []);
    return (
        <div style={{ position: 'relative' }} className='home-carousel-container home-carousel-image-container'>
            {reviews.map(item => (
                <CarouselItem
                    key={item.id}
                    handleChangeCurrent={handleChangeCurrent}
                    isCurrent={isCurrent}
                    item={item}
                    reviews={reviews}
                />
            ))}
        </div>
    );
};

export default Carousel;
