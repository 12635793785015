import React from 'react';

import Image from './image';

import gallery111Img from '../assets/gallery-1-1-1.jpg';
import gallery112Img from '../assets/gallery-1-1-2.jpg';
import gallery113Img from '../assets/gallery-1-1-3.jpg';
import gallery114Img from '../assets/gallery-1-1-4.jpg';
import gallery121Img from '../assets/gallery-1-2-1.jpg';
import gallery122Img from '../assets/gallery-1-2-2.jpg';
import gallery131Img from '../assets/gallery-1-3-1.jpg';
import gallery132Img from '../assets/gallery-1-3-2.jpg';
import gallery133Img from '../assets/gallery-1-3-3.jpg';

const Page1 = ({ currentImage, handleCurrentImage, handleSwitchOpenImage }) => {
    return (
        <>
            <div className='gallery-column'>
                <Image
                    src={gallery111Img}
                    currentImage={currentImage}
                    handleCurrentImage={handleCurrentImage}
                    handleSwitchOpenImage={handleSwitchOpenImage}
                />
                <Image
                    src={gallery112Img}
                    currentImage={currentImage}
                    handleCurrentImage={handleCurrentImage}
                    handleSwitchOpenImage={handleSwitchOpenImage}
                />
                <Image
                    src={gallery113Img}
                    currentImage={currentImage}
                    handleCurrentImage={handleCurrentImage}
                    handleSwitchOpenImage={handleSwitchOpenImage}
                />
                <Image
                    src={gallery114Img}
                    currentImage={currentImage}
                    handleCurrentImage={handleCurrentImage}
                    handleSwitchOpenImage={handleSwitchOpenImage}
                />
            </div>
            <div className='gallery-column'>
                <Image
                    src={gallery121Img}
                    currentImage={currentImage}
                    handleCurrentImage={handleCurrentImage}
                    handleSwitchOpenImage={handleSwitchOpenImage}
                />
                <Image
                    src={gallery122Img}
                    currentImage={currentImage}
                    handleCurrentImage={handleCurrentImage}
                    handleSwitchOpenImage={handleSwitchOpenImage}
                />
            </div>
            <div className='gallery-column'>
                <Image
                    src={gallery131Img}
                    currentImage={currentImage}
                    handleCurrentImage={handleCurrentImage}
                    handleSwitchOpenImage={handleSwitchOpenImage}
                />
                <Image
                    src={gallery132Img}
                    currentImage={currentImage}
                    handleCurrentImage={handleCurrentImage}
                    handleSwitchOpenImage={handleSwitchOpenImage}
                />
                <Image
                    src={gallery133Img}
                    currentImage={currentImage}
                    handleCurrentImage={handleCurrentImage}
                    handleSwitchOpenImage={handleSwitchOpenImage}
                />
            </div>
        </>
    );
};

export default Page1;
