import React, { useState } from 'react';

import Pagination from './components/pagination';
import FullScreenImage from './components/full-screen-image';

import Page1 from './components/page-1';
import Page2 from './components/page-2';
import Page3 from './components/page-3';

import './style.css';

const Gallery = () => {
    const [currentImage, setCurrentImage] = useState(null);
    const [fullScreenImage, setFullScreenImage] = useState(null);
    const [currentPage, setCurentPage] = useState(1);
    const [openImage, setOpenImage] = useState(false);
    function handleCurrentImage(image) {
        setCurrentImage(image);
    }
    function handlePage(page) {
        setCurentPage(page);
    }
    function handleSwitchOpenImage() {
        if (!openImage) setFullScreenImage(currentImage);
        setOpenImage(!openImage);
    }
    function handleCloseOpenImage() {
        setOpenImage(null);
    }

    return (
        <main className='gallery'>
            <div data-aos='fade-down' className='gallery-content container'>
                {currentPage === 1 ? (
                    <Page1
                        currentImage={currentImage}
                        handleCurrentImage={handleCurrentImage}
                        handleSwitchOpenImage={handleSwitchOpenImage}
                    />
                ) : null}
                {currentPage === 2 ? (
                    <Page2
                        currentImage={currentImage}
                        handleCurrentImage={handleCurrentImage}
                        handleSwitchOpenImage={handleSwitchOpenImage}
                    />
                ) : null}
                {currentPage === 3 ? (
                    <Page3
                        currentImage={currentImage}
                        handleCurrentImage={handleCurrentImage}
                        handleSwitchOpenImage={handleSwitchOpenImage}
                    />
                ) : null}
            </div>
            <Pagination handlePage={handlePage} currentPage={currentPage} />
            {openImage && fullScreenImage ? (
                <FullScreenImage
                    handleSwitchOpenImage={handleSwitchOpenImage}
                    fullScreenImage={fullScreenImage}
                    handleCloseOpenImage={handleCloseOpenImage}
                />
            ) : null}
        </main>
    );
};

export default Gallery;
