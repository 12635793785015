import React from 'react';

import Title from './components/title';
import Description from './components/description';
import ButtonReserve from './components/button-reserve';
import Image from './components/image';

import './style.css';

const AboutRestaurant = () => {
    return (
        <main data-aos='fade-down'>
            <section className='container about-restaurant'>
                <Image />
                <div>
                    <Title />
                    <Description />
                    <ButtonReserve />
                </div>
            </section>
        </main>
    );
};

export default AboutRestaurant;
