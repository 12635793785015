import React from 'react';

import searchIcon from '../assets/search-icon.png';

const Image = ({ currentImage, handleCurrentImage, src, handleSwitchOpenImage }) => {
    return (
        <div
            className='gallery-item'
            onClick={() => handleSwitchOpenImage()}
            onMouseEnter={() => handleCurrentImage(src)}
            onMouseLeave={() => handleCurrentImage(null)}
        >
            <img alt='gallery' width='100%' src={src} />
            {currentImage === src ? (
                <div className='gallery-item-mask'>
                    <button className='gallery-search-button'>
                        <img className='gallery-search-icon' src={searchIcon} alt='search' />
                    </button>
                </div>
            ) : null}
        </div>
    );
};

export default Image;
