import React from 'react';

import Header from '@modules/header/header';
import Footer from '@modules/footer/footer';
import Contacts from '@modules/contacts/contacts';

const ContactsPage = () => {
    return (
        <>
            <Header />
            <Contacts />
            <Footer />
        </>
    );
};

export default ContactsPage;
