import React from 'react';
import { useTranslation } from 'react-i18next';

const DeliveryTitle = () => {
    const { t } = useTranslation();
    return (
        <>
            <h3 className='home-delivery-title'>{t('delivery')}</h3>
            <div className='home-delivery-title-decoration-line-lg'></div>
            <div className='home-delivery-title-decoration-line-md'></div>
        </>
    );
};

export default DeliveryTitle;
