import React from 'react';

import Carousel from './components/carousel';
import WelcomeTitle from './components/welcome-title';
import WelcomeDescription from './components/welcome-description';
import WelcomeImage from './components/welcome-image';
import WelcomeCarousel from './components/welcome-carousel';
import DeliveryButtonTextUs from './components/delivery-button-text-us';
import DeliveryTitle from './components/delivery-title';
import DeliveryDescription1 from './components/delivery-description-1';
import DeliveryDescription2 from './components/delivery-description-2';
import DeliveryImage from './components/delivery-image';

import './style.css';

const Home = () => {
    return (
        <main>
            <section className='home-carousel'>
                <Carousel />
            </section>
            <section className='container home-welcome'>
                <WelcomeTitle />
                <div className='home-welcome-inner'>
                    <div className='home-welcome-container'>
                        <WelcomeDescription />
                        <WelcomeCarousel />
                    </div>
                    <WelcomeImage />
                </div>
            </section>
            <section className='home-delivery'>
                <div className='container home-delivery-inner'>
                    <DeliveryImage />
                    <div className='home-delivery-content'>
                        <DeliveryTitle />
                        <DeliveryDescription1 />
                        <DeliveryDescription2 />
                        <DeliveryButtonTextUs />
                    </div>
                </div>
            </section>
        </main>
    );
};

export default Home;
