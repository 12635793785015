import React from 'react';

const Title = ({ content }) => {
    return (
        <>
            <h3 className='contacts-title'>{content}</h3>
            <div className='contacts-title-decoration-line-lg'></div>
            <div className='contacts-title-decoration-line-md'></div>
        </>
    );
};

export default Title;
