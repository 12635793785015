import HomePage from '@pages/home-page';
import AboutRestaurant from '@pages/about-restaurant-page';
import MenuPage from '@pages/menu-page';
import GalleryPage from '@pages/gallery-page';
import ContactsPage from '@pages/contacts-page';
import Page404 from './pages/page-404';

export const pages = [
    {
        path: '/',
        title: 'Ресторан "Циклон" - Итальянский ресторан в Бишкеке',
        element: <HomePage />,
        id: 0,
    },
    {
        path: '/about-restaurant',
        title: 'О ресторане - Ресторан "Циклон"',
        element: <AboutRestaurant />,
        id: 1,
    },
    {
        path: '/menu',
        title: 'Меню - Ресторан "Циклон"',
        element: <MenuPage />,
        id: 2,
    },
    {
        path: '/gallery',
        title: 'Галерея - Ресторан "Циклон"',
        element: <GalleryPage />,
        id: 3,
    },
    {
        path: '/contact',
        title: 'Контакты - Ресторан "Циклон"',
        element: <ContactsPage />,
        id: 4,
    },
    {
        path: '*',
        title: '404 error',
        element: <Page404 />,
        id: 5,
    },
];
