export const pages = [
    {
        path: '/about-restaurant',
        translationKey: 'aboutRestaurant',
        id: 1,
    },
    {
        path: '/menu',
        translationKey: 'menu',
        id: 2,
    },
    {
        path: '/gallery',
        translationKey: 'gallery',
        id: 3,
    },
    {
        path: '/contact',
        translationKey: 'contacts',
        id: 4,
    },
];
