import React from 'react';

import Header from '@modules/header/header';
import Footer from '@modules/footer/footer';
import AboutRestaurant from '@modules/about-restaurant/about-restaurant';

const AboutRestaurantPage = () => {
    return (
        <>
            <Header />
            <AboutRestaurant />
            <Footer />
        </>
    );
};

export default AboutRestaurantPage;
