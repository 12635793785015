import React from 'react';
import { useTranslation } from 'react-i18next';

const AboutRestaurant = () => {
    const { t } = useTranslation();

    return (
        <section className='footer-block'>
            <div className='footer-title'>
                <h6 className='footer-title-text'>{t('aboutRestaurant')}</h6>
                <div className='footer-title-decoration-line-lg'></div>
                <div className='footer-title-decoration-line-md'></div>
            </div>
            <p className='footer-description'>{t('footerAboutRestaurantIntro')}</p>
        </section>
    );
};

export default AboutRestaurant;
