import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';

import russian from './translations/russian.json';
import english from './translations/english.json';

const resources = {
    ru: { translation: russian },
    en: { translation: english },
};

i18n.use(initReactI18next).init({
    resources,
    lng: navigator.language === 'ru-RU' || navigator.userLanguage === 'ru-RU' ? 'ru' : 'en',

    interpolation: { escapeValue: false },
});

export default i18n;
