import React from 'react';
import { useTranslation } from 'react-i18next';

import Title from './components/title';
import Description from './components/description';

import './style.css';

const Contacts = () => {
    const { t } = useTranslation();

    return (
        <main data-aos='fade-down' className='container'>
            <section className='contacts'>
                <div className='contacts-item'>
                    <Title content={t('address')} />
                    <Description
                        content={t('chuiAve136')}
                        href='https://2gis.kg/bishkek/firm/70000001035157143?m=74.5955%2C42.8759%2F16'
                    />
                </div>
                <div className='contacts-item'>
                    <Title content={t('phone')} />
                    <Description content='+996 700 533-633' href='tel:+996700533633' />
                </div>
                <div className='contacts-item'>
                    <Title content={t('workingHours')} />
                    <Description content='11:00 – 24:00' />
                </div>
                <div className='contacts-item'>
                    <Title content='Email' />
                    <Description content='info@cyclone.kg' href='mailto:info@cyclone.kg' />
                </div>
            </section>
            <section className='contacts-map'>
                <iframe
                    src='https://maps.google.com/maps?t=m&output=embed&iwloc=near&z=16&q=Cyclone+Restaurant%2C+Chuy+Avenue%2C+Bishkek'
                    frameBorder='0'
                    title='map'
                    width='100%'
                    height='100%'
                ></iframe>
            </section>
        </main>
    );
};

export default Contacts;
